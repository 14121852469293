import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import dummy_img from "../images/logo_W.png"
import Img from "gatsby-image"
import TagsLink from "../components/TagsLink"
import { RightTagsLink } from "../components/TagsLink"
const _ = require("lodash")

const WorkPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const workPosts = data.allMarkdownRemark.edges
  const tags = data.tagsGroup.group

  return (
    <Layout location={location} title={siteTitle} style={{color: "#fff"}}>
      <SEO title="Work" />
      <Container className="tags_header">
        <h2 className="title_font">My Art</h2>
      <ul className={"tags_bar tags_bar2"}>
        <div>
        <TagsLink to="/work/" children="all" />
        {tags.filter(tag => tag.fieldValue !== "Indie Support").map(tag => {
          return (
            <TagsLink to={`/tags/${_.kebabCase(tag.fieldValue)}/`} children={tag.fieldValue} />
          )
        })}
        </div>
        {tags.filter(tag => tag.fieldValue === "Indie Support").map(tag => {
          return (
            <RightTagsLink to={`/tags/${_.kebabCase(tag.fieldValue)}/`} children={tag.fieldValue} />
          )
        })}
      </ul></Container>
      <Container className="work_page">
      {workPosts.map(work => {
        const title = work.node.frontmatter.title || work.node.fields.slug
        const node = work.node
        const featuredImgFluid = node.frontmatter.img
        const year = new Date(node.frontmatter.date).getFullYear()
        const month = new Date(node.frontmatter.date).getMonth() + 1
        return (
          <article
            key={node.fields.slug}
            className="article_list_item"
            // style={{
            //   // marginTop: `-15px`,
            //   width: `25%`,
            //   marginBottom: `30px`,
            // }}
          >
            <header>
              <h3 style={{ fontSize: `1.5rem`, margin: 0}}>
                <Link
                  style={{
                    boxShadow: `none`,
                    color: "#fff",
                    // fontSize: `21px`,
                    fontWeight: `400`,
                    // fontFamily: `Montserrat`,
                  }}
                  to={node.fields.slug}
                >
                  {title}
                </Link>
              </h3>
            </header>
            <section
              style={{
                color: "#888888",
                fontSize: `17px`,
                // fontWeight: `300`,
                // fontFamily: `Montserrat`,
              }}
            >
              <p className="article_year">{month}/{year}</p>
              <p
                className="article_des"
                style={{ marginBottom: `5px` }}
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
              <Link
                // href={node.frontmatter.link}
                to={node.fields.slug}
                target="blank"
                style={{
                  color: `#ef00ef`,
                  fontSize: `15px`,
                  fontWeight: `300`,
                  fontFamily: `Montserrat`,
                }}
              >
                {!node.frontmatter.img ? (
                  <img
                    style={{ width: `100%` }}
                    src={dummy_img}
                    alt="dummy img"
                  />
                ) : (
                  <Img fluid={featuredImgFluid.childImageSharp.fluid} />
                  // <p>{JSON.stringify(featuredImgFluid)}</p>
                )}
              </Link>
            </section>
          </article>
        )
      })}
      </Container>
    </Layout>
  )
}

export default WorkPage

export const pageQuery = graphql`
         query {
           site {
             siteMetadata {
               title
             }
           }
           tagsGroup: allMarkdownRemark(
             limit: 2000
             filter: { frontmatter: { template: { eq: "work" } } }
           ) {
             group(field: frontmatter___tags) {
               fieldValue
             }
           }
           allMarkdownRemark(
             sort: { fields: [frontmatter___date], order: DESC }
             filter: { frontmatter: { template: { eq: "work" } } }
           ) {
             edges {
               node {
                 frontmatter {
                   template
                   title
                   tags
                   description
                   slug
                   date
                   category
                   # link
                   img {
                     childImageSharp {
                       # ... on ImageSharp {
                       fluid(maxWidth: 800) {
                         ...GatsbyImageSharpFluid
                       }
                       #}
                     }
                   }
                 }
                 fields {
                   slug
                 }
               }
             }
           }
         }
       `
